import {scheduleDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import React, {useState} from 'react'
import {
  Tag,
  TagSize,
  TagSkin,
  TagsAlignment,
  TagsSize,
  TagsSkin,
  TextButton,
  TextButtonPriority,
  Tags as WixTags,
} from 'wix-ui-tpa/cssVars'
import {TagsProps} from './interfaces'
import {classes as c} from './tags.st.css'

const MAX_VISIBLE_TAGS = 10

export const Tags = ({onClick, tags, className, t, filters}: TagsProps) => {
  const [expanded, setExpanded] = useState(false)

  if (!tags.length) {
    return null
  }

  return onClick ? (
    <div className={classNames(className)}>
      <WixTags
        onClick={tag => onClick(tag.value)}
        items={expanded || !filters ? tags : tags.slice(0, MAX_VISIBLE_TAGS)}
        size={TagsSize.small}
        skin={TagsSkin.light}
        alignment={TagsAlignment.left}
        className={c.tags}
      />
      {filters && tags.length > MAX_VISIBLE_TAGS ? (
        <TextButton
          className={c.seeAllButton}
          data-hook={DH.TAGS_SHOW_ALL_BUTTON}
          priority={TextButtonPriority.primary}
          onClick={() => setExpanded(!expanded)}
        >
          {t(expanded ? 'showLess' : 'showAll')}
        </TextButton>
      ) : null}
    </div>
  ) : (
    <div className={classNames(className, c.tagList)}>
      {tags.map((tag, i) => (
        <Tag key={i} tagName="div" size={TagSize.small} skin={TagSkin.light}>
          {tag.value}
        </Tag>
      ))}
    </div>
  )
}
